import React from "react";

import Image from "next/image";

import { AssetFormat } from "@/types/contentful-images";
import type { ContentfulNextImageProps } from "@/utils/image";
import { staticHeaderImageLoader } from "@/utils/image";

export const ContentfulNextImage: React.VFC<ContentfulNextImageProps> = ({
	src,
	alt,
	f,
	fit,
	...props
}) => {
	const queryParams = new URLSearchParams({
		fm: AssetFormat.webp as string,
		q: (props.quality ?? 75) as string,
		fit: fit ?? "fill",
		f: f ?? "top",
		...(props.height && { h: props.height as string }),
	});

	return (
		<Image
			{...props}
			loader={staticHeaderImageLoader}
			src={`${src}?${queryParams.toString()}`}
			alt={alt}
		/>
	);
};

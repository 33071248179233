import React from "react";

import { useBreadcrumbs } from "@/context/breadcrumbs";

import { BreadcrumbItem, BreadcrumbList } from "./components";

/**
 * @see https://w3c.github.io/aria-practices/examples/breadcrumb/index.html
 * @see https://schema.org/BreadcrumbList
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Breadcrumb
 */
export const Breadcrumbs = () => {
	const { breadcrumbs } = useBreadcrumbs();
	return (
		<BreadcrumbList>
			{breadcrumbs.map((breadcrumb, index) => {
				return (
					<BreadcrumbItem
						key={breadcrumb.id}
						index={index}
						href={breadcrumb.href}
						as={breadcrumb.as}
						position={breadcrumb.position}
						aria-current={breadcrumb.current}
					>
						{breadcrumb.title}
					</BreadcrumbItem>
				);
			})}
		</BreadcrumbList>
	);
};

import type { ImageLoader, ImageProps } from "next/image";

import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

export interface ContentfulNextImageProps extends ImageProps {
	f?: AssetFocus;
	fit?: AssetFit;
	alt?: string;
	src: string;
}

export const staticHeaderImageLoader: ImageLoader = ({ src, width }) => {
	if (width === 320) {
		return `${src}&w=640`;
	}

	if (width === 768) {
		return `${src}&w=1200`;
	}

	if (width === 1280) {
		return `${src}&w=1600`;
	}

	return `${src}&w=1800`;
};

export const imageLoaderFaceFill: ImageLoader = ({ src, width, quality }) => {
	return `${src}?q=${quality}&w=${width}&fm=${AssetFormat.webp}&f=${AssetFocus.face}&fit=${AssetFit.fill}`;
};

import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import type { Url } from "@/context/breadcrumbs";

import { Separator, StyledAnchor, StyledBreadcrumbList, StyledBreadcrumbListItem } from "./styled";

export const BreadcrumbList: React.FC = ({ children }) => {
	return (
		<StyledBreadcrumbList
			itemScope
			itemType="http://schema.org/BreadcrumbList"
			aria-label="Breadcrumb"
		>
			{children}
		</StyledBreadcrumbList>
	);
};

export const BreadcrumbItem: React.FC<
	React.HTMLAttributes<HTMLAnchorElement> & {
		index: number;
		as?: Url;
		href?: Url;
		position?: string | number;
	}
> = ({ index, as, children, href, position, "aria-current": ariaCurrent }) => {
	const { locale } = useRouter();

	return (
		<StyledBreadcrumbListItem
			itemScope
			itemProp="itemListElement"
			itemType="http://schema.org/ListItem"
		>
			<Link passHref href={href} as={as} locale={locale}>
				<StyledAnchor itemProp="item" aria-current={ariaCurrent} index={index}>
					<span itemProp="name">{children}</span>
					{!ariaCurrent && <Separator />}
				</StyledAnchor>
			</Link>
			{position && <meta itemProp="position" content={`${position}`} />}
		</StyledBreadcrumbListItem>
	);
};

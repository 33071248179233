import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const StyledBreadcrumbList = styled.ol`
	display: block;
	margin: var(--spacing-xxs) 0;
	padding: 0;
	list-style: none;
`;

export const StyledBreadcrumbListItem = styled.li`
	display: inline-flex;
	position: relative;
	align-content: stretch;
	align-items: stretch;
	margin: 0;
	padding: 0;
	list-style: none;
	&:last-child {
		margin-right: 0;
	}
`;

export const StyledAnchor = styled.a<{ index: number }>`
	display: inline-flex;
	align-content: center;
	align-items: center;
	padding: 4px 0;
	color: currentColor;
	text-decoration: none;
	${props => {
		if (props["aria-current"]) {
			return css`
				text-decoration: none;
				opacity: 0.5;
			`;
		}
	}};
`;

export const StyledSeparator = styled.span`
	padding: 0 var(--spacing-xxxs);
	pointer-events: none;
`;

export const Separator: React.FC = () => {
	return <StyledSeparator>/</StyledSeparator>;
};

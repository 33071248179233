import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Badge } from "@/components/badge";
import type { PropsWithTheme } from "@/theme";

export const StyledCardWrapper = styled.div<PropsWithTheme & { vertical?: boolean }>`
	position: relative;
	height: 0;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	${({ theme: { mq, tokens }, vertical }) => css`
		border-radius: ${tokens.borderRadius["3xl"]};
		padding-bottom: ${vertical ? "120%" : "75%"};
		@media ${mq.l} {
			padding-bottom: ${vertical ? "125%" : "80%"};
		}
	`};
`;

export const StyledGradient = styled.div`
	position: absolute;
	z-index: 1;
	right: 0;
	bottom: 0;
	left: 0;
	height: 45%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const StyledBadge = styled(Badge)`
	align-self: flex-start;
`;

export const StyledDescription = styled.div<PropsWithTheme & { large?: boolean }>`
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: var(--spacing-xs);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	> div {
		margin-top: auto;
		margin-bottom: 0;
	}

	${({ theme: { mq, palette }, large }) => css`
		color: ${palette.freeze[0]};
		@media ${mq.l} {
			margin: ${large ? "var(--spacing-m)" : "var(--spacing-xs)"};
		}
	`};
`;
export const ImageCardBase = styled.a`
	display: block;
	text-decoration: none;
`;

export const ImageCard = ImageCardBase.withComponent("div");
export const ImageCardLink = styled(ImageCardBase)<PropsWithTheme<HTMLAnchorElement>>`
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			&:hover {
				${StyledCardWrapper} {
					box-shadow: 0 4px 17px rgba(0, 0, 0, 0.2);
				}
			}
		}
	`};
`;

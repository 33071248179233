import { BackgroundColor, COLOR_PALETTE } from "@/theme";

export const getWave = ({ flip, dark }: { flip?: boolean; dark?: boolean }) =>
	`/assets/images/wave_${flip ? "left" : "right"}_${dark ? "light" : "dark"}.png`;

export const isDark = (color: string) => ["black", "darkGrey"].includes(color);

/**
 * BackgroundColor is a selectable value for editors in Contentful
 * It is used so that they can manually configure the background color of slots
 * We need to map those values to a color palette value
 */
export const getBackgroundColor = (backgroundColor: BackgroundColor) => {
	switch (backgroundColor) {
		case BackgroundColor.grey:
			return COLOR_PALETTE.freeze[50];
		case BackgroundColor.white:
			return COLOR_PALETTE.freeze[0];
		default:
			return COLOR_PALETTE.freeze[1000];
	}
};

import React from "react";

import type { UrlObject } from "url";

export type Url = string | UrlObject;

export const BreadcrumbsContext = React.createContext<RawBreadcrumb[]>([]);
export const { Provider: BreadcrumbsProvider } = BreadcrumbsContext;

export interface RawBreadcrumb {
	href: Url;
	as?: Url;
	title: string;
}

export interface Breadcrumb extends RawBreadcrumb {
	id: string;
	position: number;
	current?: boolean;
}

export const useBreadcrumbs = (): { breadcrumbs: Breadcrumb[] } => {
	const randomString = (Math.random() + 1).toString(36).substring(7); // generated a 5 letter random string, see https://stackoverflow.com/a/8084248/1302662
	const rawBreadcrumbs = React.useContext<RawBreadcrumb[]>(BreadcrumbsContext);

	const breadcrumbs = rawBreadcrumbs.map((item, index) => {
		return {
			...item,
			id: `${item.href}-${index}-${randomString}`,
			position: index + 1,
			current: index + 1 === rawBreadcrumbs.length,
		};
	});
	return { breadcrumbs };
};

import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BreakLines } from "@/components/break-lines";
import { Spacer } from "@/components/layout/components";
import { getBackgroundColor, getWave, isDark } from "@/components/slot/utils";
import { Typography } from "@/components/typography/typography";
import { PAGE_LIMIT } from "@/constants/layout";
import { TypographyVariant } from "@/theme";
import type { BackgroundColor, PropsWithTheme } from "@/theme";

export const SlotWrapper = styled.div<PropsWithTheme>`
	${({ theme: { mq } }) => css`
		padding: var(--spacing-xs) 0;

		@media ${mq.l} {
			padding: var(--spacing-m) 0;
		}
	`}
`;

export const Background = styled(SlotWrapper)<
	PropsWithTheme & { backgroundColor: BackgroundColor; flip?: boolean; wave?: boolean }
>`
	${({ theme: { palette, mq }, backgroundColor, wave, flip }) => css`
		--background: ${getBackgroundColor(backgroundColor)};
		background-color: ${getBackgroundColor(backgroundColor)};
		color: ${isDark(backgroundColor) ? palette.freeze[0] : palette.freeze[1000]};

		@media ${mq.l} {
			background-image: ${wave
				? `url(${getWave({ flip, dark: isDark(backgroundColor) })})`
				: "none"};
			background-size: ${PAGE_LIMIT / 3}px auto;
			background-position: ${flip ? 100 : 0}% 100%;
			background-repeat: no-repeat;
		}
	`};
`;

export const StyledLink = styled.a<PropsWithTheme<HTMLAnchorElement>>`
	${({ theme: { palette } }) => css`
		color: ${palette.blue[500]};
		text-decoration: none;
	`};
`;

export const StyledImage = styled.img`
	overflow: hidden;
	width: 100%;
	${({ theme: { tokens } }) => css`
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;

interface SlotHeaderProps {
	headline?: string;
	subtitle?: string;
	centered?: boolean;
	centeredOnMobile?: boolean;
}

export const SlotHeader: React.VFC<SlotHeaderProps> = ({
	headline,
	subtitle,
	centered = false,
	centeredOnMobile = false,
}) => {
	return (
		<>
			{headline && (
				<Typography
					tight
					component="h2"
					centered={centered}
					centeredOnMobile={centeredOnMobile}
					variant={TypographyVariant.headlineSerifLG}
				>
					<BreakLines text={headline} />
				</Typography>
			)}

			{subtitle && (
				<Typography top centered={centered} centeredOnMobile={centeredOnMobile}>
					<BreakLines text={subtitle} />
				</Typography>
			)}

			{(headline || subtitle) && <Spacer spacing="m" />}
		</>
	);
};

import React from "react";

import { useTranslation } from "next-i18next";

import Image from "next/image";

import type { ImageProps } from "@/types/image";
import { imageLoaderFaceFill } from "@/utils/image";

import { BadgeVariants } from "../badge";

import { StyledCardWrapper, StyledDescription, StyledGradient, StyledBadge } from "./components";

export const ImageCard: React.FC<{
	image: ImageProps;
	isNew?: boolean;
	large?: boolean;
	vertical?: boolean;
}> = ({ children, image, isNew, large, vertical = false }) => {
	const { t } = useTranslation("common");
	return (
		<StyledCardWrapper vertical={vertical}>
			{image?.url && (
				<Image
					loader={imageLoaderFaceFill}
					src={image.url}
					alt={image.description}
					layout="fill"
					quality={75}
					sizes="(max-width: 1199px) 50vw, 30vw"
					objectFit="cover"
					loading="lazy"
				/>
			)}
			<StyledGradient />
			<StyledDescription large={large}>
				{isNew && (
					<StyledBadge variant={BadgeVariants.secondary}>{t("common:new")}</StyledBadge>
				)}
				<div>{children}</div>
			</StyledDescription>
		</StyledCardWrapper>
	);
};
